import React from 'react';
import ReactMarkdown from '../../lib/ReactMarkdown';
import { LinkRenderer } from '../../lib/utils';

const LongBulletItem = ({ item }) => {

  return (
    <div className="cLongBulletItem">
      <div className="cLongBulletItem-question">
      <ReactMarkdown
        renderers={{ link: LinkRenderer }}
        source={item[0]}
        escapeHtml={false} />
      </div>
      <div className="cLongBulletItem-value">
      <ReactMarkdown
        renderers={{ link: LinkRenderer }}
        source={item[1]}
        escapeHtml={false} />
      </div>
    </div>
  )
}

export default LongBulletItem