import React from 'react'
import { LinkRenderer } from '../../../lib/utils'
import Fade from 'react-reveal/Fade'
import Byline from './Byline'

import ReactMarkdown from '../../../lib/ReactMarkdown'

class BodyText extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      text: props.text,
      type: props.type,
      subSections: props.subSections,
    }
  }

  listRef = React.createRef()

  componentDidMount() {
    const {
      listRef,
      props: {
        type
      }
    } = this

    if(type === 'list' || type === 'annotation-list') {
      const h2s = (listRef?.current && listRef.current.querySelectorAll('h2')) || []
      for (let i = 0; i < h2s.length; i++) {
        const element = h2s[i];
        if(element.tagName === 'H2') {
          const h2 = element
          if(!h2.outerHTML.includes('<br></h2>')) {
            h2.classList.add('inline-h2')
          }
        }
      }
    }

    if(typeof document !== 'undefined' && type === 'annotation-list') {
      const anchors = document.querySelectorAll('.body-text-annotation-list > a')
      for (let i = 0; i < anchors.length; i++) {
        const anchor = anchors[i];
        anchor.setAttribute('href', `#${anchor.innerHTML}`)
      }
  
      let ols = document.querySelectorAll('.body-text-annotation-list > ol')
  
      for (let i = 0; i < ols.length; i++) {
        const ol = ols[i];
        if(ol.children[0].nodeName === 'LI') {
          var number = ol.getAttribute('start') || 1;
          var newOl = document.createElement("OL");
          
          for (let ii = 0; ii < ol.children.length; ii++) {
            const formatedNumber = number < 10 ? `0${number}` : number
            const li = ol.children[ii];
            newOl.innerHTML += `<a class="anchor" id="${formatedNumber}" name="${formatedNumber}">${li.outerHTML}</a>`
            
            number++
          }
  
          ol.innerHTML = newOl.innerHTML
        }
      }
    }
  }

  render() {
    const {
      listRef,
      state: {
        text: sText,
        subSections,
        type
      }
    } = this

    let annotationStartNumber = 1
    let text = sText
    
    text = text.split("\n").map(v => v.trim()).join("\n")
    text = text.replace(
      /<qa>(er|enroute)<\/qa>(.*?)(\n\n<qa>|\n\n|$)/gis,
      '<qa>$1</qa><span class="body-text-q">$2</span>\n\n<qa>'
    )
    text = text.replace(/<qa>/g, "<span class='body-text-qa'>")
    text = text.replace(/<\/qa>/g, '</span>')
    text = text.replace(
      /<anno>/g,
      "<span class='body-text-annotation-list superscript'>"
    )
    text = text.replace(/<\/anno>/g, '</span>')
    let response = []

    if (type === 'drop-cap') {
      let firstLetter = sText.slice(0, 1)
      let dropCap = <span className="body-text-drop-cap">{firstLetter}</span>

      response.push(
        <div key={'drop-cap-' + response.length}>
          {dropCap}
          <ReactMarkdown
            source={text.slice(1)}
            escapeHtml={false}
            renderers={{ link: LinkRenderer, 
              text: (node) => {
                return `${node.value}`.replace(/-/g,'\u2013')
              }
            }} />
        </div>
      )
    } else if (
      type === 'list' ||
      type === 'annotation-list'
    ) {
      if (annotationStartNumber > 1) {
        text = text.replace('1.', annotationStartNumber + '.')
        text = text.replace(
          '<ol>',
          "<ol start='" + annotationStartNumber + "'>"
        )
      }

      response.push(
        <div
          key="bodytext"
          ref={type === 'list' ? listRef : null}
          className={`body-text-${type}`} >
          <div className='body-text-content'>
          <ReactMarkdown
              source={text}
              escapeHtml={false}
              renderers={{ 
                link: LinkRenderer,
                text: (node) => {
                  return `${node.value}`.replace(/-/g,'\u2013')
                },
                root: ({children}) => (<>{children.map((child, childIdx) => (<Fade key={`md-${childIdx}`}>{child}</Fade>))}</>)
              }} />
          </div>
        </div>
      )
    } else {
      // "body-text"
      response.push(
        <div
          key={'body-text-' + response.length}
          className={`${text.includes('zoom.us') ? 'embeded-zoom' : ''}`} >
          <div className='body-text-content'>
            <ReactMarkdown
              source={text}
              escapeHtml={false}
              renderers={{ 
                link: LinkRenderer,
                root: ({children}) => (<>{children.map((child, childIdx) => (<Fade key={`md-${childIdx}`}>{child}</Fade>))}</>)
              }} />
          </div>
        </div>
      )
    }
    
    if (subSections) {
      response.push(
        <Byline
          key={'subsection-' + response.length}
          content={subSections[0].fields} />
      )
    }

    const component = (
      <div
        className="row body-text"
        id="bodytext" >
        {response}
      </div>
    )
    return this.props.wrap ? 
      <div
        class="full-width-fade light-section"
        style={{margin: '0 auto'}}
        data-texttype="Body Text"
        data-module="textStyles" >
        <div
          class="full-width-fade--inner"
          style={{margin: '0 auto'}} >
          {component}
        </div>
      </div>
    : component
  }
}

export default BodyText