import React, {
  useEffect,
  useRef,
} from 'react'
import Slider from 'react-slick'
import CarouselItem from './components/CarouselItem'

const Carousel = ({
  title,
  colors,
  items,
  isNumbered
}) => {
  const slickSlide_Ref = useRef()
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '100px',
    slidesToShow: 4,
    speed: 500,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: '60px',
          slidesToShow: 3
          
        }
      },
      {
        breakpoint: 1060,
        settings: {
          centerPadding: '40px',
          slidesToShow: 2
          
        }
      },
      {
        breakpoint: 700,
        settings: {
          centerPadding: '50px',
          slidesToShow: 1
        }
      }
    ]
  }

  useEffect(() => {
    const slickSlide = slickSlide_Ref.current.innerSlider.list
    let slides = slickSlide.children[0].children

    if(slides.length) {
      const buttons = slickSlide.nextSibling.tagName === 'BUTTON' ? [
        slickSlide.nextSibling,
        slickSlide.previousSibling
      ] : []
      
      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i]
        if(slide.classList.contains('slick-cloned')) {
          slide.setAttribute('aria-hidden', true)
          continue;
        }
        
        slide.setAttribute('aria-hidden', false)
        slide.addEventListener('focus', () => {
          for (let i = 0; i < slides.length; i++) {
            const slide = slides[i]
            if(slide.classList.contains('slick-cloned')) {
              continue;
            }
            
            slide.setAttribute('aria-hidden', false)  
          }
        })

        const anchor = slide.children[0].children[0]
        anchor.setAttribute('tabindex', '0')
        anchor.addEventListener('focus', () => {
          const index = slide.getAttribute('data-index')
          slickSlide_Ref.current.slickGoTo(index)
        })
      }
  
      for (let i = 0; i < buttons.length; i++) {
        const button = buttons[i];
        button.setAttribute('aria-hidden', true)
        button.setAttribute('tabindex', '-1')
      }
    }
  })

  const formattedCarouselItems = [];

  for (let i = 0; i < items.length; i++) {
    let position = i;
    
    if(colors) {
      while (position > colors.length - 1) {
        position -= colors.length;
      };
    }

    formattedCarouselItems.push({
      fields: {
        ...items[i].fields,
        color: colors ? colors[position] : undefined
      },
      sys: items[i].sys
    })
  }

  return (
    <div className="cCarousel">
      <div className="cCarousel-wrapper">
        <h2
          className={`
            cCarousel-title
            ${title.length <= 10 ? 'cCarousel-title--short' : ''}
          `} >
          {title}
        </h2>
        <div className="cCarousel-carousel">
          {typeof window === 'undefined' ? null : (
            <Slider
              ref={slickSlide_Ref}
              {...settings} >
              {formattedCarouselItems.map((item, i) => (
                <CarouselItem
                  key={`Carousel-${item.sys.id}`}
                  item={item.fields}
                  color={item.color}
                  number={isNumbered && i + 1} /> 
              )).filter(i => i)}
            </Slider>
          )}
        </div>
      </div>
    </div>
  )
}

export default Carousel