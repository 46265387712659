import React from 'react';
import Spotlight from './components/Spotlight';

const SpotlightGrid = ({ spotlights }) => {
  return (
    <div className="cSpotlightGrid">
      <div className="cSpotlightGrid-wrapper">
        {spotlights.map(spotlight => (
          <Spotlight
            {...spotlight.fields}
            id={spotlight.sys.id}
            key={`spotlight-${spotlight.sys.id}`} />
        ))}
      </div>
    </div>
  );
};

export default SpotlightGrid;