import React, { useContext } from "react";
import { sContext } from "../../../../context";
import AnchorTag from "../../AnchorTag";

const CarouselItem = ({
  number,
  item: {
    itemTitle,
    itemSubtitle,
    itemImage: {
      fields: itemImage
    },
    itemPath,
    color = 'white'
  }
}) => {
  const localePath = useContext(sContext).currentLocale

  return (
    <AnchorTag
      path={itemPath}
      localePath={localePath}
      attributes={{
        className: 'cCarouselItem',
        'aria-label': `${itemTitle}, ${itemSubtitle}`
      }} >
      <div
        className="cCarouselItem-copy-wrapper"
        style={{ backgroundColor: color }} >
        <div className="cCarouselItem-copy-container">
          <h3 className="cCarouselItem-title">
            {itemTitle}
          </h3>
          <span className="cCarouselItem-subtitle">
            {itemSubtitle}
          </span>
        </div>
      </div>
      <div className="cCarouselItem-image-wrapper">
        <img
          className='cCarouselItem-image'
          src={itemImage.file.url}
          alt={itemImage.description} />
        <span className='cCarouselItem-number'>
          {number}
        </span>
      </div>
    </AnchorTag>
  )
};

export default CarouselItem;