import React, { useEffect, useState, useMemo } from 'react'
import LazyLoadBgImage from '../LazyLoadBgImage'
import { LinkRenderer, secondaryColorPiker } from '../../../lib/utils'
import { ArticleSchema } from '../../../lib/schemaMarkup'
import ReactMarkdown from '../../../lib/ReactMarkdown'
import { getLandscapeAsset } from '../../../lib/getAssets'

const TopHeroImageVideo = ({
  type,
  title,
  subtitle,
  textOverlay,
  sponsorImage,
  text,
  sponsored_by,
  isEmbededZoom,
  metaDescription,
  images,
  isBannerStyle,
  pageBgColor
}) => {
  const [asset, setAsset] = useState({})

  const sizes = [
    [0, 480],
    [320, 640],
    [640, 960],
    [960, 1280],
    [1200, 2048],
  ].reverse()
  const windowSize =  typeof window !== 'undefined' ? window.innerWidth : 0
  const width = sizes.find(s => s[0] <= windowSize)[1]

  let sponsoredBy
  if(sponsored_by) {
    sponsoredBy = sponsored_by
  } else if(type?.includes('Air Canada enRoute')) {
    sponsoredBy = type
  }

  useEffect(() => {
    setAsset(getLandscapeAsset({
      ...images,
      topType: 'image-video'
    }))
  }, [images])

  const secondaryColor = useMemo(() => {
    return secondaryColorPiker(pageBgColor)
  }, [pageBgColor])

  return (
    <div
      className={`
        tophero
        ${isBannerStyle ? 'is-banner-style' : ''}
      `}
      style={{ '--secondary-color': secondaryColor || '' }} >
      {isBannerStyle && (
        <div
          className={`
            tophero-banner-wrapper
            ${subtitle ? 'tophero-banner-wrapper--has-subtitle' : ''}
          `} >
            <div className="tophero-banner-container">
            <h1>
              <ReactMarkdown
                escapeHtml={false}
                source={title} />
            </h1>
            <span>{subtitle}</span>
          </div>
        </div>
      )}
      <div>
        <LazyLoadBgImage
          className={`
            row
            tophero-main
            ${isEmbededZoom ? 'is-embeded-zoom-top' : ''}
          `}
          asset={asset || {}}
          overrides={{ w: width, q: 90 }}
          gradient={!isBannerStyle ? 'radial' : ''} >
          <div className="col-lg-12">
            {(type || sponsoredBy) && (
              <div className="row tophero-tag">
                {sponsoredBy ? (
                  <p>
                    {sponsoredBy}
                  </p>
                ) : (
                  <p>{type}</p>
                )}
              </div>
            )}
            <div className="row tophero-title">
              <div className="tophero-title-content">
                {isBannerStyle && (
                  <span>{textOverlay}</span>
                )}
                {title && !isBannerStyle && (
                  <h1>
                    <ReactMarkdown
                      escapeHtml={false}
                      source={`${title}${subtitle ? ' — ' : ''}`} />
                  </h1>
                )}
                {subtitle && !isBannerStyle && (
                  <h2>
                    <ReactMarkdown
                      escapeHtml={false}
                      source={subtitle} />          
                  </h2>
                )}
              </div>
            </div>
            {sponsorImage && (
              <div className="row tophero-sponsor">
                <div className="col-lg-2 tophero-sponsor-text">
                  <ReactMarkdown
                    source={text}
                    escapeHtml={false}
                    renderers={{ link: LinkRenderer }} />
                </div>
                <LazyLoadBgImage
                  asset={sponsorImage.fields}
                  className="col-lg-2 tophero-sponsor-image" />
              </div>
            )}
          </div>
          <ArticleSchema
            type={sponsoredBy || type}
            headline={title}
            about={metaDescription} />
        </LazyLoadBgImage>
      </div>
    </div>
  )
}

export default TopHeroImageVideo