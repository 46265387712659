import React from 'react'
import { LinkRenderer } from '../../../lib/utils'

import ReactMarkdown from '../../../lib/ReactMarkdown'

class LeadInText extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      text: props.text,
      size: props.size,
      attribution: props.attribution
    }
  }
  render() {
    let response = null
    if (this.state.size === 'large') {
      response = (
        <div className="leadintext-large col-lg-6 offset-lg-3">
          <ReactMarkdown
            source={this.state.text}
            escapeHtml={false}
            renderers={{ link: LinkRenderer,
              text: (node) => {
                return `${node.value}`.replace(/-/g,'\u2013')
              } }}
          />
          <div className="leadintext-divider"></div>
        </div>
      )
    } else if (this.state.size === 'small') {
      response = (
        <div className="leadintext-small col-lg-6 offset-lg-3">
          <ReactMarkdown
            source={this.state.text}
            escapeHtml={false}
            renderers={{ link: LinkRenderer,
              text: (node) => {
                return `${node.value}`.replace(/-/g,'\u2013')
              } }}
          />
          <div className="leadintext-divider"></div>
        </div>
      )
    }
    return (
      <div className="row" style={{ padding: '3% 0' }}>
        {response}
      </div>
    )
  }
}

export default LeadInText
