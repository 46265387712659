import React from 'react'
import { htmlFormatCustom, LinkRenderer } from '../../../lib/utils'

import ReactMarkdown from '../../../lib/ReactMarkdown'

class PullQuote extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      text: props.text,
      size: props.size,
      attribution: props.attribution
    }
  }
  render() {
    let response = null
    const html = htmlFormatCustom(this.state.text)

    return (
      <div className="pullquote">
        <div className={`
          ${this.state.size === 'large' && 'pullquote-large'}
          ${this.state.size === 'small' && 'pullquote-small col-lg-8 offset-lg-1'}
        `} >
          {html.includes('<mark') ? (
            <div dangerouslySetInnerHTML={{__html: html}} />
          ) : (
            <ReactMarkdown
              source={html}
              escapeHtml={false}
              renderers={{ link: LinkRenderer,
                text: (node) => {
                  return `${node.value}`.replace(/-/g,'\u2013')
                }
              }} />
          )}
          {this.state.attribution && (
            <p className="pullquote-attribution">
              {this.state.attribution}
            </p>
          )}
        </div>
      </div>
    )
  }
}

export default PullQuote
