import React, {
  useEffect,
  useRef,
} from 'react'
import Slider from 'react-slick'
import LoadImage from '../LoadImage'

const AssetCarousel = ({ images }) => {
  const slickSlide_Ref = useRef()
  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    focusOnSelect: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000
  }

  useEffect(() => {
    const slickSlide = slickSlide_Ref.current.innerSlider.list
    let slides = slickSlide.children[0].children

    if(slides.length) {
      const buttons = slickSlide.nextSibling.tagName === 'BUTTON' ? [
        slickSlide.nextSibling,
        slickSlide.previousSibling
      ] : []
      
      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i]
        if(slide.classList.contains('slick-cloned')) {
          slide.setAttribute('aria-hidden', true)
          continue;
        }
        
        slide.setAttribute('aria-hidden', false)
        slide.addEventListener('focus', () => {
          for (let i = 0; i < slides.length; i++) {
            const slide = slides[i]
            if(slide.classList.contains('slick-cloned')) {
              continue;
            }
            
            slide.setAttribute('aria-hidden', false)  
          }
        })

        const anchor = slide.children[0].children[0]
        anchor.setAttribute('tabindex', '0')
        anchor.addEventListener('focus', () => {
          const index = slide.getAttribute('data-index')
          slickSlide_Ref.current.slickGoTo(index)
        })
      }
  
      for (let i = 0; i < buttons.length; i++) {
        const button = buttons[i];
        button.setAttribute('aria-hidden', true)
        button.setAttribute('tabindex', '-1')
      }
    }
  })

  return (
    <div className="cAssetCarousel">
      {typeof window === 'undefined' ? null : (
        <Slider
          ref={slickSlide_Ref}
          {...settings} >
          {images.map(image => (
            <div
              key={`Carousel-${image.sys.id}`}
              className='cAssetCarousel-image-wrapper' >
                <LoadImage
                  asset={image.fields}
                  className="cAssetCarousel-image"
                  sizes={[
                    { viewport: 1200, width: 1400 },
                    { viewport: 900, width: 1200 },
                    { viewport: 600, width: 900 },
                    { viewport: 0, width: 600 }
                  ]} />
            </div>
          )).filter(i => i)}
        </Slider>
      )}
    </div>
  )
}

export default AssetCarousel