import React from 'react';
import { LinkRenderer } from '../../../../lib/utils';
import LazyLoadImage from '../../LazyLoadImage';
import RichText from '../../RichText';
import Fade from 'react-reveal/Fade'
import AnchorTag from '../../AnchorTag';

const Spotlight = ({
  image: {
    fields: image
  },
  imageCaption,
  text,
  links,
  id
}) => {
  return (
    <Fade>
      <div className="cSpotlight">
        <div className="cSpotlight-image-wrapper">
          <LazyLoadImage
            asset={image}
            sizes={[
              { viewport: 1400, width: 650 },
              { viewport: 0, width: 400 }
            ]}  />
        </div>
        <div className="cSpotlight-text">
          <RichText
            json={text}
            renderers={{ link: LinkRenderer }} />
        </div>
        <span className="cSpotlight-caption">
          {imageCaption}
        </span>
        {links && (
          <div className="cSpotlight-links">
            {links.map(link => (
              <AnchorTag
                key={`${id}-${link.sys.id}`}
                path={link.fields.url}
                attributes={{
                  className: 'cSpotlight-link'
                }} >
                {link.fields.alt}
              </AnchorTag>
            )
            )}
          </div>
        )}
      </div>
    </Fade>
  );
};

export default Spotlight;
