import React, { useMemo } from 'react';
import LongBulletItem from './LongBulletItem';

const LongBullets = ({ items }) => {

  const fomattedItems = useMemo(() => {
    const final = items.split('\n');
    for (let i = 0; i < final.length; i++) {
      final[i] = final[i].split('|');
    };

    return final;
  }, [items]);

  return (
    <div className="cLongBullets">
      {fomattedItems && fomattedItems.map(item => (
        <LongBulletItem
          key={`LongBulletItem-${item[0]}`}
          item={item} />
      ))}
    </div>
  );
};

export default LongBullets;